import React from "react"
import NewLayout from "../components/new_layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import Card from "../components/v2023/UI/Card/card"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"

const TimeStampPolicies = ({ data, pageContext }) => {
    const page = data.page
    const lang = pageContext.langKey

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo.image_2?.localFile?.publicURL}
            />

            <main className="main main--time-stamp">
                <div className="main__section main__section--100 white-section brown" >
                    <div className="container" data-aos="fade-up">
                        <h1 className="merriweather">{page.title}</h1>
                        <div className="container__text">
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="grid-lg-3">
                            {page.files.map((file, index) => (
                                <Card
                                    key={index}
                                    name={file.name}
                                    showMore={true}
                                    url={file.file.localFile.publicURL}
                                    lang={lang}
                                    download={true}
                                />
                            ))}
                            <Card
                                name="CRL 1"
                                showMore
                                url="https://crl1.uanataca.com/public/pki/crl/2021CA1sub.crl"
                                lang={lang}
                            />
                            <Card
                                name="CRL 2"
                                showMore
                                url="https://crl2.uanataca.com/public/pki/crl/2021CA1sub.crl"
                                lang={lang}
                            />
                            <Card
                                name="OCSP 1"
                                showMore
                                url="https://ocsp1.uanataca.com/public/pki/ocsp/"
                                lang={lang}
                            />
                            <Card
                                name="OCSP 2"
                                showMore
                                url="https://ocsp2.uanataca.com/public/pki/ocsp/"
                                lang={lang}
                            />
                        </div>
                    </div>
                </div>
                <Certifications />
                <Identity lang={lang} />
            </main>
        </NewLayout>
    )
}

export default TimeStampPolicies

export const timeStampPoliciesQuery = graphql`
    query ($langKey: String) {
        page: strapi2023TimeStampPolicies ( locale: { eq: $langKey } ) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            title
            description
            files {
                name
                file {
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
`
